import React from "react";

import Layout from "components/Layout";
import InfoPage from "components/InfoPage";

import ThankYouIcon from "icons/thank-you.svg";

const ThankYouPage = () => {
  return (
    <Layout
      location="Strona podziękowania"
      blue_header
      seo={{ title: "Dziękujemy" }}
    >
      <InfoPage
        icon={<ThankYouIcon />}
        header="Dziękujemy za wysłanie wiadomości"
        text="Skontaktujemy się z Tobą najpóźniej w ciągu 24 godzin."
      />
    </Layout>
  );
};

export default ThankYouPage;
